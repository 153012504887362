/* tslint:disable */
/* eslint-disable */
/**
 * osakaserver
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError, operationServerMap } from './base';

/**
 * 
 * @export
 * @interface AccessKey
 */
export interface AccessKey {
    /**
     * 
     * @type {string}
     * @memberof AccessKey
     */
    'key': string;
}
/**
 * 
 * @export
 * @interface BookDetails
 */
export interface BookDetails {
    /**
     * 
     * @type {string}
     * @memberof BookDetails
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof BookDetails
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof BookDetails
     */
    'authors': string;
    /**
     * 
     * @type {string}
     * @memberof BookDetails
     */
    'description': string;
    /**
     * 
     * @type {string}
     * @memberof BookDetails
     */
    'type': string;
}
/**
 * 
 * @export
 * @interface DownloadBookContents404Response
 */
export interface DownloadBookContents404Response {
    /**
     * 
     * @type {number}
     * @memberof DownloadBookContents404Response
     */
    'code': number;
    /**
     * 
     * @type {string}
     * @memberof DownloadBookContents404Response
     */
    'error': string;
}
/**
 * 
 * @export
 * @interface EnrichmentDetails
 */
export interface EnrichmentDetails {
    /**
     * 
     * @type {string}
     * @memberof EnrichmentDetails
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof EnrichmentDetails
     */
    'book': string;
    /**
     * 
     * @type {string}
     * @memberof EnrichmentDetails
     */
    'type': string;
    /**
     * 
     * @type {number}
     * @memberof EnrichmentDetails
     */
    'page': number;
    /**
     * 
     * @type {number}
     * @memberof EnrichmentDetails
     */
    'offset': number;
    /**
     * 
     * @type {string}
     * @memberof EnrichmentDetails
     */
    'content': string;
}
/**
 * 
 * @export
 * @interface IBook
 */
export interface IBook {
    /**
     * 
     * @type {string}
     * @memberof IBook
     */
    '_id': string;
    /**
     * 
     * @type {string}
     * @memberof IBook
     */
    'account': string;
    /**
     * 
     * @type {string}
     * @memberof IBook
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof IBook
     */
    'authors': string;
    /**
     * 
     * @type {string}
     * @memberof IBook
     */
    'description': string;
    /**
     * 
     * @type {string}
     * @memberof IBook
     */
    'contents': string;
    /**
     * 
     * @type {string}
     * @memberof IBook
     */
    'type': IBookTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof IBook
     */
    'createdAt': string;
}

export const IBookTypeEnum = {
    Digital: 'digital',
    Paper: 'paper'
} as const;

export type IBookTypeEnum = typeof IBookTypeEnum[keyof typeof IBookTypeEnum];

/**
 * Make all properties in T optional
 * @export
 * @interface PartialBookDetails
 */
export interface PartialBookDetails {
    /**
     * 
     * @type {string}
     * @memberof PartialBookDetails
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartialBookDetails
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartialBookDetails
     */
    'authors'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartialBookDetails
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartialBookDetails
     */
    'type'?: string;
}
/**
 * Make all properties in T optional
 * @export
 * @interface PartialEnrichmentDetails
 */
export interface PartialEnrichmentDetails {
    /**
     * 
     * @type {string}
     * @memberof PartialEnrichmentDetails
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartialEnrichmentDetails
     */
    'book'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartialEnrichmentDetails
     */
    'type'?: string;
    /**
     * 
     * @type {number}
     * @memberof PartialEnrichmentDetails
     */
    'page'?: number;
    /**
     * 
     * @type {number}
     * @memberof PartialEnrichmentDetails
     */
    'offset'?: number;
    /**
     * 
     * @type {string}
     * @memberof PartialEnrichmentDetails
     */
    'content'?: string;
}
/**
 * 
 * @export
 * @interface UpdateBook200Response
 */
export interface UpdateBook200Response {
    /**
     * 
     * @type {string}
     * @memberof UpdateBook200Response
     */
    'id': string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateBook200Response
     */
    'message': string;
}
/**
 * 
 * @export
 * @interface UploadBookContents200Response
 */
export interface UploadBookContents200Response {
    /**
     * 
     * @type {string}
     * @memberof UploadBookContents200Response
     */
    'message': string;
}
/**
 * 
 * @export
 * @interface VersionModel
 */
export interface VersionModel {
    /**
     * 
     * @type {string}
     * @memberof VersionModel
     */
    'serverVersion': string;
    /**
     * 
     * @type {number}
     * @memberof VersionModel
     */
    'clientApiMinVersion': number;
    /**
     * 
     * @type {number}
     * @memberof VersionModel
     */
    'clientApiMaxVersion': number;
}

/**
 * AuthorBookApi - axios parameter creator
 * @export
 */
export const AuthorBookApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {BookDetails} bookDetails 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createBook: async (bookDetails: BookDetails, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'bookDetails' is not null or undefined
            assertParamExists('createBook', 'bookDetails', bookDetails)
            const localVarPath = `/authorbook/createBook`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(bookDetails, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteBook: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteBook', 'id', id)
            const localVarPath = `/authorbook/deleteBook/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadBookContents: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('downloadBookContents', 'id', id)
            const localVarPath = `/authorbook/downloadBookContents/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllBooks: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/authorbook/getAllBooks`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBook: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getBook', 'id', id)
            const localVarPath = `/authorbook/getBook/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {PartialBookDetails} partialBookDetails 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateBook: async (id: string, partialBookDetails: PartialBookDetails, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateBook', 'id', id)
            // verify required parameter 'partialBookDetails' is not null or undefined
            assertParamExists('updateBook', 'partialBookDetails', partialBookDetails)
            const localVarPath = `/authorbook/updateBook/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(partialBookDetails, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {File} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadBookContents: async (id: string, file: File, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('uploadBookContents', 'id', id)
            // verify required parameter 'file' is not null or undefined
            assertParamExists('uploadBookContents', 'file', file)
            const localVarPath = `/authorbook/uploadBookContents/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AuthorBookApi - functional programming interface
 * @export
 */
export const AuthorBookApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AuthorBookApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {BookDetails} bookDetails 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createBook(bookDetails: BookDetails, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createBook(bookDetails, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuthorBookApi.createBook']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteBook(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpdateBook200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteBook(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuthorBookApi.deleteBook']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async downloadBookContents(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.downloadBookContents(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuthorBookApi.downloadBookContents']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllBooks(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PartialBookDetails>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllBooks(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuthorBookApi.getAllBooks']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBook(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BookDetails>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBook(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuthorBookApi.getBook']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {PartialBookDetails} partialBookDetails 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateBook(id: string, partialBookDetails: PartialBookDetails, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpdateBook200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateBook(id, partialBookDetails, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuthorBookApi.updateBook']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {File} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadBookContents(id: string, file: File, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UploadBookContents200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadBookContents(id, file, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuthorBookApi.uploadBookContents']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * AuthorBookApi - factory interface
 * @export
 */
export const AuthorBookApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AuthorBookApiFp(configuration)
    return {
        /**
         * 
         * @param {BookDetails} bookDetails 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createBook(bookDetails: BookDetails, options?: RawAxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.createBook(bookDetails, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteBook(id: string, options?: RawAxiosRequestConfig): AxiosPromise<UpdateBook200Response> {
            return localVarFp.deleteBook(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadBookContents(id: string, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.downloadBookContents(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllBooks(options?: RawAxiosRequestConfig): AxiosPromise<Array<PartialBookDetails>> {
            return localVarFp.getAllBooks(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBook(id: string, options?: RawAxiosRequestConfig): AxiosPromise<BookDetails> {
            return localVarFp.getBook(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {PartialBookDetails} partialBookDetails 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateBook(id: string, partialBookDetails: PartialBookDetails, options?: RawAxiosRequestConfig): AxiosPromise<UpdateBook200Response> {
            return localVarFp.updateBook(id, partialBookDetails, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {File} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadBookContents(id: string, file: File, options?: RawAxiosRequestConfig): AxiosPromise<UploadBookContents200Response> {
            return localVarFp.uploadBookContents(id, file, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AuthorBookApi - object-oriented interface
 * @export
 * @class AuthorBookApi
 * @extends {BaseAPI}
 */
export class AuthorBookApi extends BaseAPI {
    /**
     * 
     * @param {BookDetails} bookDetails 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthorBookApi
     */
    public createBook(bookDetails: BookDetails, options?: RawAxiosRequestConfig) {
        return AuthorBookApiFp(this.configuration).createBook(bookDetails, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthorBookApi
     */
    public deleteBook(id: string, options?: RawAxiosRequestConfig) {
        return AuthorBookApiFp(this.configuration).deleteBook(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthorBookApi
     */
    public downloadBookContents(id: string, options?: RawAxiosRequestConfig) {
        return AuthorBookApiFp(this.configuration).downloadBookContents(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthorBookApi
     */
    public getAllBooks(options?: RawAxiosRequestConfig) {
        return AuthorBookApiFp(this.configuration).getAllBooks(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthorBookApi
     */
    public getBook(id: string, options?: RawAxiosRequestConfig) {
        return AuthorBookApiFp(this.configuration).getBook(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {PartialBookDetails} partialBookDetails 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthorBookApi
     */
    public updateBook(id: string, partialBookDetails: PartialBookDetails, options?: RawAxiosRequestConfig) {
        return AuthorBookApiFp(this.configuration).updateBook(id, partialBookDetails, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {File} file 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthorBookApi
     */
    public uploadBookContents(id: string, file: File, options?: RawAxiosRequestConfig) {
        return AuthorBookApiFp(this.configuration).uploadBookContents(id, file, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * AuthorEnrichmentApi - axios parameter creator
 * @export
 */
export const AuthorEnrichmentApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {EnrichmentDetails} enrichmentDetails 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createEnrichment: async (enrichmentDetails: EnrichmentDetails, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'enrichmentDetails' is not null or undefined
            assertParamExists('createEnrichment', 'enrichmentDetails', enrichmentDetails)
            const localVarPath = `/authorenrichment/createEnrichment`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(enrichmentDetails, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} book 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEnrichments: async (book: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'book' is not null or undefined
            assertParamExists('getEnrichments', 'book', book)
            const localVarPath = `/authorenrichment/getEnrichments/{book}`
                .replace(`{${"book"}}`, encodeURIComponent(String(book)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} book 
         * @param {number} page 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPageEnrichments: async (book: string, page: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'book' is not null or undefined
            assertParamExists('getPageEnrichments', 'book', book)
            // verify required parameter 'page' is not null or undefined
            assertParamExists('getPageEnrichments', 'page', page)
            const localVarPath = `/authorenrichment/getPageEnrichments/{book}/{page}`
                .replace(`{${"book"}}`, encodeURIComponent(String(book)))
                .replace(`{${"page"}}`, encodeURIComponent(String(page)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {PartialEnrichmentDetails} partialEnrichmentDetails 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEnrichment: async (id: string, partialEnrichmentDetails: PartialEnrichmentDetails, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateEnrichment', 'id', id)
            // verify required parameter 'partialEnrichmentDetails' is not null or undefined
            assertParamExists('updateEnrichment', 'partialEnrichmentDetails', partialEnrichmentDetails)
            const localVarPath = `/authorenrichment/updateEnrichment/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(partialEnrichmentDetails, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AuthorEnrichmentApi - functional programming interface
 * @export
 */
export const AuthorEnrichmentApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AuthorEnrichmentApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {EnrichmentDetails} enrichmentDetails 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createEnrichment(enrichmentDetails: EnrichmentDetails, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createEnrichment(enrichmentDetails, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuthorEnrichmentApi.createEnrichment']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} book 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEnrichments(book: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<EnrichmentDetails>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEnrichments(book, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuthorEnrichmentApi.getEnrichments']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} book 
         * @param {number} page 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPageEnrichments(book: string, page: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<EnrichmentDetails>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPageEnrichments(book, page, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuthorEnrichmentApi.getPageEnrichments']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {PartialEnrichmentDetails} partialEnrichmentDetails 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateEnrichment(id: string, partialEnrichmentDetails: PartialEnrichmentDetails, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateEnrichment(id, partialEnrichmentDetails, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuthorEnrichmentApi.updateEnrichment']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * AuthorEnrichmentApi - factory interface
 * @export
 */
export const AuthorEnrichmentApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AuthorEnrichmentApiFp(configuration)
    return {
        /**
         * 
         * @param {EnrichmentDetails} enrichmentDetails 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createEnrichment(enrichmentDetails: EnrichmentDetails, options?: RawAxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.createEnrichment(enrichmentDetails, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} book 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEnrichments(book: string, options?: RawAxiosRequestConfig): AxiosPromise<Array<EnrichmentDetails>> {
            return localVarFp.getEnrichments(book, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} book 
         * @param {number} page 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPageEnrichments(book: string, page: number, options?: RawAxiosRequestConfig): AxiosPromise<Array<EnrichmentDetails>> {
            return localVarFp.getPageEnrichments(book, page, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {PartialEnrichmentDetails} partialEnrichmentDetails 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEnrichment(id: string, partialEnrichmentDetails: PartialEnrichmentDetails, options?: RawAxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.updateEnrichment(id, partialEnrichmentDetails, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AuthorEnrichmentApi - object-oriented interface
 * @export
 * @class AuthorEnrichmentApi
 * @extends {BaseAPI}
 */
export class AuthorEnrichmentApi extends BaseAPI {
    /**
     * 
     * @param {EnrichmentDetails} enrichmentDetails 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthorEnrichmentApi
     */
    public createEnrichment(enrichmentDetails: EnrichmentDetails, options?: RawAxiosRequestConfig) {
        return AuthorEnrichmentApiFp(this.configuration).createEnrichment(enrichmentDetails, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} book 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthorEnrichmentApi
     */
    public getEnrichments(book: string, options?: RawAxiosRequestConfig) {
        return AuthorEnrichmentApiFp(this.configuration).getEnrichments(book, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} book 
     * @param {number} page 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthorEnrichmentApi
     */
    public getPageEnrichments(book: string, page: number, options?: RawAxiosRequestConfig) {
        return AuthorEnrichmentApiFp(this.configuration).getPageEnrichments(book, page, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {PartialEnrichmentDetails} partialEnrichmentDetails 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthorEnrichmentApi
     */
    public updateEnrichment(id: string, partialEnrichmentDetails: PartialEnrichmentDetails, options?: RawAxiosRequestConfig) {
        return AuthorEnrichmentApiFp(this.configuration).updateEnrichment(id, partialEnrichmentDetails, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * DefaultApi - axios parameter creator
 * @export
 */
export const DefaultApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {AccessKey} accessKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkAccess: async (accessKey: AccessKey, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'accessKey' is not null or undefined
            assertParamExists('checkAccess', 'accessKey', accessKey)
            const localVarPath = `/general/checkAccess`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(accessKey, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVersion: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/general/getversion`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DefaultApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {AccessKey} accessKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async checkAccess(accessKey: AccessKey, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.checkAccess(accessKey, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.checkAccess']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getVersion(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VersionModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getVersion(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.getVersion']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DefaultApiFp(configuration)
    return {
        /**
         * 
         * @param {AccessKey} accessKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkAccess(accessKey: AccessKey, options?: RawAxiosRequestConfig): AxiosPromise<boolean> {
            return localVarFp.checkAccess(accessKey, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVersion(options?: RawAxiosRequestConfig): AxiosPromise<VersionModel> {
            return localVarFp.getVersion(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI {
    /**
     * 
     * @param {AccessKey} accessKey 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public checkAccess(accessKey: AccessKey, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).checkAccess(accessKey, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getVersion(options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getVersion(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * EnrichmentApi - axios parameter creator
 * @export
 */
export const EnrichmentApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} book 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        enrichment: async (book: string, id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'book' is not null or undefined
            assertParamExists('enrichment', 'book', book)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('enrichment', 'id', id)
            const localVarPath = `/enrichment/enrichment/{book}/{id}`
                .replace(`{${"book"}}`, encodeURIComponent(String(book)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * EnrichmentApi - functional programming interface
 * @export
 */
export const EnrichmentApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = EnrichmentApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} book 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async enrichment(book: string, id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.enrichment(book, id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EnrichmentApi.enrichment']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * EnrichmentApi - factory interface
 * @export
 */
export const EnrichmentApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = EnrichmentApiFp(configuration)
    return {
        /**
         * 
         * @param {string} book 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        enrichment(book: string, id: string, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.enrichment(book, id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * EnrichmentApi - object-oriented interface
 * @export
 * @class EnrichmentApi
 * @extends {BaseAPI}
 */
export class EnrichmentApi extends BaseAPI {
    /**
     * 
     * @param {string} book 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EnrichmentApi
     */
    public enrichment(book: string, id: string, options?: RawAxiosRequestConfig) {
        return EnrichmentApiFp(this.configuration).enrichment(book, id, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ReaderApi - axios parameter creator
 * @export
 */
export const ReaderApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReaderBookById: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getReaderBookById', 'id', id)
            const localVarPath = `/reader/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReaderBooks: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/reader`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ReaderApi - functional programming interface
 * @export
 */
export const ReaderApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ReaderApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getReaderBookById(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IBook>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getReaderBookById(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ReaderApi.getReaderBookById']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getReaderBooks(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<IBook>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getReaderBooks(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ReaderApi.getReaderBooks']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * ReaderApi - factory interface
 * @export
 */
export const ReaderApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ReaderApiFp(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReaderBookById(id: string, options?: RawAxiosRequestConfig): AxiosPromise<IBook> {
            return localVarFp.getReaderBookById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReaderBooks(options?: RawAxiosRequestConfig): AxiosPromise<Array<IBook>> {
            return localVarFp.getReaderBooks(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ReaderApi - object-oriented interface
 * @export
 * @class ReaderApi
 * @extends {BaseAPI}
 */
export class ReaderApi extends BaseAPI {
    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReaderApi
     */
    public getReaderBookById(id: string, options?: RawAxiosRequestConfig) {
        return ReaderApiFp(this.configuration).getReaderBookById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReaderApi
     */
    public getReaderBooks(options?: RawAxiosRequestConfig) {
        return ReaderApiFp(this.configuration).getReaderBooks(options).then((request) => request(this.axios, this.basePath));
    }
}



