// src/firebaseConfig.ts
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyD8l85WJvpPFFX7ENxYjBst-zdk0Z-b250",
  authDomain: "enrichedbooks-osakaauthor.firebaseapp.com",
  projectId: "enrichedbooks-osakaauthor",
  storageBucket: "enrichedbooks-osakaauthor.appspot.com",
  messagingSenderId: "386090668387",
  appId: "1:386090668387:web:ba0ceba7996b8ff094c356",
  measurementId: "G-982QJFWYSZ"
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
