// src/firebaseUiConfig.ts
import { 
  EmailAuthProvider,
  GoogleAuthProvider,
} from "firebase/auth";
import { auth } from './firebaseConfig';
import * as firebaseui from 'firebaseui';

// Configure FirebaseUI.
export const uiConfig: firebaseui.auth.Config = {
  signInFlow: 'popup', // Use 'popup' instead of redirect for authentication
  signInSuccessUrl: '/dashboard', // Redirect after successful sign-in
  signInOptions: [
    EmailAuthProvider.PROVIDER_ID,
    GoogleAuthProvider.PROVIDER_ID,
  ],
  tosUrl: '/terms-of-service', // Terms of service URL
  privacyPolicyUrl: '/privacy-policy', // Privacy policy URL
  callbacks: {
    signInSuccessWithAuthResult: (authResult, redirectUrl) => {
      // Custom logic on sign-in success
      console.log('User signed in:', authResult.user);
      return false; // Prevent automatic redirect after sign-in
    },
    uiShown: () => {
      const loader = document?.getElementById('firebaseui-loader');
      if (loader) {
        loader.style.display = 'none';
      }
    }
  }
};

// Initialize the FirebaseUI widget.
export const ui = new firebaseui.auth.AuthUI(auth);
