import React, { useEffect } from 'react';
import { ui, uiConfig } from './firebaseUiConfig';
import 'firebaseui/dist/firebaseui.css';

const FirebaseAuth: React.FC = () => {
  useEffect(() => {
    // Start the FirebaseUI widget when the component mounts
    ui.start('#firebaseui-auth-container', uiConfig);
  }, []);

  return (
    <>
      <div id="firebaseui-auth-container"></div>
      <div id="firebaseui-loader">Loading...</div>
    </>
  );
};

export default FirebaseAuth;
